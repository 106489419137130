import { Navigate, Route, Routes } from "react-router-dom";
import Signin from "./pages/auth/Signin";
import Signup from "./pages/auth/Signup";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import Dashboard from "./pages/dashboard/Dashboard";
import Logout from "./pages/auth/Logout";
import Spinner from "./components/Spinner";
import ForgotPassword from "./pages/auth/ForgotPassword";
import MultiFactorAuthenticationSetting from "./pages/dashboard/settings/MultiFactorAuthenticationSetting";
import { serverEndpoint } from "./util/config";
import AppLayout from "./layout/AppLayout";
import './scss/style.scss'
import AddUser from "./pages/dashboard/user/AddUser";
import ViewUsers from "./pages/dashboard/user/ViewUsers";
import UnauthorizedAccess from "./pages/UnauthorizedAccess";
import ProtectedRoute from "./components/ProtectedRoute";
import PaymentSuccess from "./pages/payments/PaymentSuccess";

function App() {
    const [userDetails, setUserDetails] = useState(null);
    const [loading, setLoading] = useState(true);

    const updateUserDetails = (newUserDetails) => {
        setUserDetails(newUserDetails);
    };

    const isUserLoggedIn = useCallback(() => {
        axios.get(`${serverEndpoint}/authorize/is-authorized-user`, { withCredentials: true })
            .then(response => {
                const user = response.data.user;
                setUserDetails(user);
                setLoading(false);
            })
            .catch(error => {
                // Assuming the user is not logged in!
                setLoading(false);
            });
    }, []);

    useEffect(() => {
        isUserLoggedIn();
    }, []);

    if (loading) {
        return <Spinner />;
    }

    return (
        <>
            <Routes>
                <Route path="/" element={userDetails ? <Navigate to="/dashboard" /> : <Signin updateUserDetails={updateUserDetails} />} />
                <Route path="/signin" element={userDetails ? <Navigate to="/dashboard" /> : <Signin updateUserDetails={updateUserDetails} />} />
                <Route path="/forgot-password" element={userDetails ? <Navigate to="/dashboard" /> : <ForgotPassword />} />
                <Route path="/signup" element={userDetails ? <Navigate to="/dashboard" /> : <Signup />} />
                <Route path="/logout" element={userDetails ? <Logout updateUserDetails={updateUserDetails} /> : <Navigate to="/signin" />} />
                <Route path="/dashboard" element={
                    userDetails ? (
                        <AppLayout userDetails={userDetails}>
                            <Dashboard userDetails={userDetails} />
                        </AppLayout>
                    ) : (
                        <Navigate to="/signin" />
                    )
                }
                />
                <Route path="/settings" element={
                    userDetails ? (
                        <AppLayout userDetails={userDetails}>
                            <MultiFactorAuthenticationSetting userDetails={userDetails} />
                        </AppLayout>
                    ) : (
                        <Navigate to="/signin" />
                    )
                }
                />
                <Route
                    path="/users/add-user"
                    element={
                        <ProtectedRoute
                            userDetails={userDetails}
                            section="User Management"
                            action="Add"
                            userPermissions={userDetails?.permissions}
                            isProtected={true}
                            element={
                                <AppLayout userDetails={userDetails}>
                                    <AddUser userDetails={userDetails} />
                                </AppLayout>
                            }
                        />
                    }
                />
                <Route
                    path="/users/view-all"
                    element={
                        <ProtectedRoute
                            userDetails={userDetails}
                            section="User Management"
                            action="View"
                            userPermissions={userDetails?.permissions}
                            isProtected={true}
                            element={
                                <AppLayout userDetails={userDetails}>
                                    <ViewUsers userDetails={userDetails} />
                                </AppLayout>
                            }
                        />
                    }
                />
                <Route path="/unauthorized" element={userDetails ? (
                    <AppLayout userDetails={userDetails}>
                        <UnauthorizedAccess />
                    </AppLayout>
                ) : (
                    <UnauthorizedAccess />
                )} />

                <Route path="/payment-success" element={userDetails ? (
                    <AppLayout userDetails={userDetails}>
                        <PaymentSuccess userDetails={userDetails} />
                    </AppLayout>
                ) : (
                    <PaymentSuccess />
                )} />
            </Routes>
        </>
    );
}

export default App;
