import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    CCloseButton, CSidebar, CSidebarBrand, CSidebarHeader,
    CSidebarNav, CNavTitle, CNavItem, CNavLink
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import { TbHexagonLetterJ } from "react-icons/tb";
import { cilGroup, cilUserPlus } from '@coreui/icons';
import { Link, NavLink } from 'react-router-dom';

function AppSidebar() {
    const dispatch = useDispatch()
    const unfoldable = useSelector((state) => state.sidebarUnfoldable)
    const sidebarShow = useSelector((state) => state.sidebarShow)

    return (
        <CSidebar
            className="border-end"
            colorScheme="dark"
            position="fixed"
            unfoldable={unfoldable}
            visible={sidebarShow}
            onVisibleChange={(visible) => {
                dispatch({ type: 'set', sidebarShow: visible })
            }}
        >
            <CSidebarHeader className="border-bottom">
                <CSidebarBrand className='dropdown-item'>
                    <Link to="/" className="dropdown-item">
                        <TbHexagonLetterJ size={24} className="mb-1 mx-1" />
                        JBL Prep Master
                    </Link>
                </CSidebarBrand>
                <CCloseButton
                    className="d-lg-none"
                    dark
                    onClick={() => dispatch({ type: 'set', sidebarShow: false })}
                />
            </CSidebarHeader>
            <CSidebarNav>
                <CNavTitle>Manage Users</CNavTitle>

                <CNavItem>
                    <CNavLink to="/users/add-user" as={NavLink}>
                        <CIcon icon={cilUserPlus} className="me-2" /> Add User
                    </CNavLink>
                </CNavItem>
                <CNavItem>
                    <CNavLink to="/users/view-all" as={NavLink}>
                        <CIcon icon={cilGroup} className="me-2" /> View Users
                    </CNavLink>
                </CNavItem>
                {/* 
                Drop-down example in sidebar
                <CNavGroup
                    toggler={
                        <>
                            <CIcon customClassName="nav-icon" icon={cilPuzzle} /> Nav dropdown
                        </>
                    }
                >
                    <CNavItem href="#"><span className="nav-icon"><span className="nav-icon-bullet"></span></span> Nav dropdown item</CNavItem>
                    <CNavItem href="#"><span className="nav-icon"><span className="nav-icon-bullet"></span></span> Nav dropdown item</CNavItem>
                </CNavGroup>
                */}
            </CSidebarNav>
        </CSidebar>
    )
}

export default AppSidebar;
