import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ element, userDetails, section, action, userPermissions, isProtected = true }) => {
    console.log(userPermissions);
    const hasPermission = userPermissions?.[section]?.includes(action) ?? false;
    console.log(hasPermission);

    // Check for authentication only if it's a protected route
    if (isProtected && !userDetails) {
        return <Navigate to="/signin" />;
    }

    // If userDetails are present but permissions are missing, redirect to unauthorized page
    if (isProtected && hasPermission === false) {
        return <Navigate to="/unauthorized" />;
    }

    // Render the component if userDetails are not mandatory or permissions check passes
    return element;
};

export default ProtectedRoute;
