import { Link } from "react-router-dom";

function Dashboard({ userDetails }) {
    return (
        <div className="container">
            <div className="text-center">
                <h2>Cool features coming soon!</h2>
                <p>
                    In the meantime, you can enable Two-Factor Authentication if you haven't already, 
                    by visiting <strong>Settings</strong> under the <strong>User</strong> icon above.
                </p>
            </div>
        </div>
    );
}

export default Dashboard;