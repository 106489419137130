import React, { useState } from "react";
import { Button, Col, Collapse, Container, Nav, Row, Form } from "react-bootstrap";
import classNames from "classnames";
import { FaCheck } from "react-icons/fa6";
import { pricingList, serverEndpoint } from "../../util/config";
import axios from "axios";
import {
    EmbeddedCheckoutProvider,
    EmbeddedCheckout
} from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';

const PricingItem = ({ pricing, initiatePayment, loading }) => (
    <div className="active rounded-4 shadow-lg p-3 p-lg-5"
    >
        <h3>{pricing.planTitle}</h3>
        <div className="mb-3">
            {pricing.discountPrice && (
                <>
                    <span className="fs-5 text-muted fw-bold text-decoration-line-through">{pricing.price}</span>
                    <span className="ms-2 fs-3 fw-bold">{pricing.discountPrice}</span>
                    <span className="ms-2 opacity-50">{pricing.timeline}</span>
                </>
            )}

            {!pricing.discountPrice && (
                <>
                    <span className="fs-3 fw-bold">{pricing.price}</span>
                    <span className="ms-2 opacity-50">{pricing.timeline}</span>
                </>
            )}

        </div>
        <p className="mb-3 opacity-50">
            {pricing.description}
        </p>
        <Nav className="flex-column">
            {pricing.features.map((feature, i) => (
                <li
                    className={classNames({
                        "mb-3": pricing.features.length - 1 > i,
                    })}
                    key={i}
                >
                    <span className="me-2">
                        <FaCheck className="opacity-75 text-success" />
                    </span>
                    <span className="opacity-75">{feature.label}</span>
                </li>
            ))}
        </Nav>

        {loading && (
            <button className="btn btn-primary w-100 mt-4" type="button" disabled="">
                <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                    aria-hidden="true"
                />
            </button>
        )}

        {!loading && (
            <Button className="btn btn-primary w-100 mt-4" onClick={() => initiatePayment(pricing.planId)}>
                Choose plan
            </Button>
        )}

    </div>
);

function PaymentPlans({ userDetails }) {
    const [isActiveYearlyPricing, setIsActiveYearlyPricing] = useState(false);
    const switchActiveTimeline = (tab) => setIsActiveYearlyPricing(tab);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);

    const [checkoutClientSecret, setCheckoutClientSecret] = useState(null);

    const initiatePayment = async (planId) => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.post(`${serverEndpoint}/payment/create-customer`, {
                company_name: userDetails.businessName,
                email: userDetails.email,
                phone_number: userDetails.phone,
            }, { withCredentials: true });
            console.log(response);

            axios.post(`${serverEndpoint}/payment/create-checkout-session`, {
                customer_id: response.data.customer.id,
                plan_type: planId,
            }, { withCredentials: true })
                .then((response) => {
                    setCheckoutClientSecret(response.data.clientSecret);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                    setLoading(false);
                    setError("Something went wrong, try selecting plan again");
                });
        } catch (error) {
            console.log(error);
            setLoading(false);
            setError("Something went wrong, try selecting plan again");
        }
    };

    // Present checkout form
    if (checkoutClientSecret) {
        const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUSHABLE_KEY);
        const stripeOptions = {
            clientSecret: checkoutClientSecret
        };

        return (
            <div id="checkout">
                {error && (
                    <div className="alert alert-danger" role="alert">
                        {error}
                    </div>
                )}
                <EmbeddedCheckoutProvider stripe={stripePromise} options={stripeOptions}>
                    <EmbeddedCheckout />
                </EmbeddedCheckoutProvider>
            </div>
        );
    }

    return (
        <section className="container mt-5 card p-4">
            <Container>
                <Row className="justify-content-center mb-3">
                    <Col lg={6} xl={5} className="text-center">
                        {error && (
                            <div className="alert alert-danger" role="alert">
                                {error}
                            </div>
                        )}
                        <h2 className="mb-2">
                            Flexible Plan for you
                        </h2>
                        <p className="text-muted">
                            Choice suitable plan for you.
                        </p>
                    </Col>
                </Row>
                <div className="text-center mb-4">
                    Monthly
                    <div className="d-inline-flex align-items-center mx-2">
                        <Form.Check
                            type="switch"
                            label=""
                            checked={isActiveYearlyPricing}
                            onChange={(e) => switchActiveTimeline(e.target.checked)}
                        />
                    </div>
                    Annual
                </div>
                <Collapse in={!isActiveYearlyPricing}>
                    <Row className="justify-content-center">
                        {pricingList.monthlyPricings.map((pricing, i) => (
                            <Col md={6} lg={4} className="mt-4" key={i}>
                                <PricingItem pricing={pricing} initiatePayment={initiatePayment} loading={loading} />
                            </Col>
                        ))}
                    </Row>
                </Collapse>
                <Collapse in={isActiveYearlyPricing}>
                    <Row className="justify-content-center">
                        {pricingList.yearlyPricings.map((pricing, i) => (
                            <Col md={6} lg={4} className="mt-4" key={i}>
                                <PricingItem pricing={pricing} initiatePayment={initiatePayment} loading={loading} />
                            </Col>
                        ))}
                    </Row>
                </Collapse>
            </Container>
        </section>
    );
};

export default PaymentPlans;