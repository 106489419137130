const managerPermissions = {
    "User Management": [],
    "Inbound": ["Add", "Edit", "Delete", "View"],
    "In-stock": ["Add", "Edit", "Delete", "View"],
    "Component Library": ["Add", "Edit", "Delete", "View"],
    "Amazon Listing": ["Add", "Edit", "Delete", "View"],
    "Task Management": ["Add", "Edit", "Delete", "View"],
    "Reporting": ["Add", "Edit", "Delete", "View"]
};

const staffPermissions = {
    "User Management": [],
    "Inbound": [],
    "In-stock": ["View"],
    "Component Library": ["View"],
    "Amazon Listing": [],
    "Task Management": ["Edit", "View"],
    "Reporting": []
};

const viewerPermissions = {
    "User Management": [],
    "Inbound": ["View"],
    "In-stock": ["View"],
    "Component Library": ["View"],
    "Amazon Listing": ["View"],
    "Task Management": ["View"],
    "Reporting": ["View"]
};

export { managerPermissions, staffPermissions, viewerPermissions };