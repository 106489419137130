import { useState } from "react";
import axios from 'axios';
import { serverEndpoint } from "../../util/config";
import { Link } from "react-router-dom";
import { TbHexagonLetterJ } from "react-icons/tb";

function ForgotPassword() {
    const [status, setStatus] = useState('INITIATE');
    const [loading, setLoading] = useState(false);
    const [successMessage, setSuccessMessage] = useState(null);
    const [formData, setFormData] = useState({
        email: '',
        code: '',
        password: '',
        confirmPassword: ''
    });

    const [errors, setErrors] = useState({});

    const validateInitiateResetForm = () => {
        let isValid = true;
        const newErrors = {};

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (!emailRegex.test(formData.email)) {
            newErrors.email = 'Please provide a valid Email';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    }

    const validateChangePasswordForm = () => {
        let isValid = true;
        const newErrors = {};

        if (formData.code.length !== 6) {
            newErrors.code = 'Please provide a valid Code';
            isValid = false;
        }

        const passwordRegex = /^(?!.*\s{2,})(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[\s!@#$%^&*(),.?":{}|<>])[A-Za-z\d\s!@#$%^&*(),.?":{}|<>]{8,}$/;
        if (!passwordRegex.test(formData.password)) {
            newErrors.password = 'Please provide a valid Password';
            isValid = false;
        }

        if (formData.password !== formData.confirmPassword) {
            newErrors.confirmPassword = 'Passwords must match';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    }

    const handleChange = (e) => {
        if (e.target.name === "code") {
            const inputValue = e.target.value;

            // Allow only numbers and limit length to 6
            if (/^\d*$/.test(inputValue) && inputValue.length <= 6) {
                setFormData({
                    ...formData,
                    [e.target.name]: e.target.value
                });
            }
        } else {
            setFormData({
                ...formData,
                [e.target.name]: e.target.value
            });
        }
    };

    const handleInitiateResetSubmit = async (e) => {
        e.preventDefault();
        if (validateInitiateResetForm()) {
            setLoading(true);
            axios.post(`${serverEndpoint}/auth/forgot-password`, {
                email: formData.email,
            }, { withCredentials: true })
                .then(response => {
                    setStatus('RESET_PASSWORD');
                    setLoading(false);
                }).catch(error => {
                    console.log(error);
                    setErrors({ message: "Something went wrong, please try again!" });
                    setLoading(false);
                });
        }
    };

    const handleChangePasswordSubmit = async (e) => {
        e.preventDefault();
        if (validateChangePasswordForm()) {
            setLoading(true);
            axios.post(`${serverEndpoint}/auth/change-password`, {
                email: formData.email,
                verificationCode: formData.code,
                newPassword: formData.password
            }, { withCredentials: true })
                .then(response => {
                    setSuccessMessage("Your password has been successfully changed.");
                    setStatus('COMPLETE');
                    setLoading(false);
                }).catch(error => {
                    if (error.response?.data?.error && error.response.data.error === 'Invalid verification code provided, please try again.') {
                        setErrors({ message: "Invalid Code!" });
                    } else {
                        setErrors({ message: "Something went wrong, please try again!" });
                    }
                    setLoading(false);
                });
        }
    };

    return (
        <section className="h-100">
            <div className="container h-100">
                <div className="row justify-content-sm-center h-100">
                    <div className="col-xxl-4 col-xl-5 col-lg-5 col-md-7 col-sm-9">
                        {status === 'INITIATE' && (
                            <div className="card shadow-lg">
                                <div className="text-center mt-2">
                                    <TbHexagonLetterJ size={90} className="text-primary" />
                                </div>
                                <div className="card-body p-4">
                                    {errors.message && (
                                        <div className="alert alert-danger" role="alert">
                                            {errors.message}
                                        </div>
                                    )}

                                    {successMessage && (
                                        <div className="alert alert-success" role="alert">
                                            {successMessage}
                                        </div>
                                    )}
                                    <h1 className="fs-4 card-title fw-bold">Forgot your password?</h1>
                                    <p className="text-muted mb-4">Enter your Email below and we will send a message to reset your password
                                    </p>
                                    <form onSubmit={handleInitiateResetSubmit}>
                                        <div className="mb-3">
                                            <label className="mb-2 text-muted" htmlFor="email">
                                                Email
                                            </label>
                                            <input id="email" type="email" className={errors.email ? 'form-control is-invalid' : 'form-control'}
                                                name="email" autoFocus onChange={handleChange} value={formData.email}
                                            />
                                            <div className="invalid-feedback">Please enter a valid Email.</div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            {loading && (
                                                <button className="btn btn-primary ms-auto" type="button" disabled="">
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            )}

                                            {!loading && (
                                                <button className="btn btn-primary ms-auto" type="submit">
                                                    Reset my password
                                                </button>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}

                        {status === 'RESET_PASSWORD' && (
                            <div className="card shadow-lg">
                                <div className="card-body p-5">
                                    {errors.message && (
                                        <div className="alert alert-danger" role="alert">
                                            {errors.message}
                                        </div>
                                    )}

                                    {successMessage && (
                                        <div className="alert alert-success" role="alert">
                                            {successMessage}
                                        </div>
                                    )}
                                    <h1 className="fs-4 card-title fw-bold">Reset Password</h1>
                                    <p className="text-muted mb-4">
                                        We have sent a password reset code by email to {formData.email}. Enter it below to reset your password.
                                    </p>
                                    <form onSubmit={handleChangePasswordSubmit}>
                                        <div className="mb-3">
                                            <label className="mb-2 text-muted" htmlFor="code">
                                                Code
                                            </label>
                                            <input id="code" type="text" className={errors.code ? 'form-control is-invalid' : 'form-control'}
                                                name="code" autoFocus onChange={handleChange} value={formData.code}
                                            />
                                            <div className="invalid-feedback">Code is mandatory.</div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="mb-2 text-muted" htmlFor="password">
                                                Password
                                            </label>
                                            <input id="password" type="password" className={errors.password ? 'form-control is-invalid' : 'form-control'}
                                                name="password" onChange={handleChange} value={formData.password}
                                            />
                                            <div className="invalid-feedback">Password must be at least 8 characters long and contain lowercase, uppercase, number, and special character/space, without leading or trailing spaces.</div>
                                        </div>
                                        <div className="mb-3">
                                            <label className="mb-2 text-muted" htmlFor="confirmPassword">
                                                Confirm Password
                                            </label>
                                            <input id="confirmPassword" type="password" className={errors.confirmPassword ? 'form-control is-invalid' : 'form-control'}
                                                name="confirmPassword" onChange={handleChange} value={formData.confirmPassword}
                                            />
                                            <div className="invalid-feedback">Passwords must match.</div>
                                        </div>
                                        <div className="d-flex align-items-center">
                                            {loading && (
                                                <button className="btn btn-primary ms-auto" type="button" disabled="">
                                                    <span
                                                        className="spinner-border spinner-border-sm"
                                                        role="status"
                                                        aria-hidden="true"
                                                    />
                                                </button>
                                            )}

                                            {!loading && (
                                                <button className="btn btn-primary ms-auto" type="submit">
                                                    Change my password
                                                </button>
                                            )}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        )}

                        {status === 'COMPLETE' && (
                            <div className="card shadow-lg">
                                <div className="card-body p-5">
                                    {errors.message && (
                                        <div className="alert alert-danger" role="alert">
                                            {errors.message}
                                        </div>
                                    )}

                                    {successMessage && (
                                        <div className="alert alert-success" role="alert">
                                            {successMessage}
                                        </div>
                                    )}
                                </div>
                                <div className="card-footer py-3 border-0">
                                    <div className="text-center">
                                        <Link to="/signin" className="text-link">
                                            Signin
                                        </Link> with your new password!.
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                </div>
            </div>
        </section>

    );
}

export default ForgotPassword;