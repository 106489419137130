const serverEndpoint = process.env.REACT_APP_SERVER_ENDPOINT;

const pricingList = {
    monthlyPricings: [
        {
            planId: "standard_monthly",
            planTitle: "Standard Account",
            price: "£14.99",
            discountPrice: "£11.99",
            timeline: "p/month",
            description:
                "First 500 - Price Locked In For Life!",
            features: [
                { isActive: true, label: "1 Log in" },
                { isActive: true, label: "Track Inbound Inventory" },
                { isActive: false, label: "Manage Bundles" },
                { isActive: false, label: "Create To-Do Lists" },
                { isActive: false, label: "Sync with Amazon Account" },
            ],
            isActive: false,
        },
        {
            planId: "advanced_monthly",
            planTitle: "Advanced Account",
            price: "£18.99",
            discountPrice: "£14.99",
            timeline: "p/month",
            description:
                "First 500 - Price Locked In For Life!",
            features: [
                { isActive: true, label: "10 Loggins" },
                { isActive: true, label: "Track Inbound Inventory" },
                { isActive: false, label: "Manage Bundles" },
                { isActive: false, label: "Create To-Do Lists" },
                { isActive: false, label: "Sync with Amazon Account" },
            ],
            isActive: false,
        }
    ],
    yearlyPricings: [
        {
            planId: "standard_yearly",
            planTitle: "Standard Account",
            price: "£149.90",
            discountPrice: "£119.90",
            timeline: "p/year",
            description:
                "First 500 - Price Locked In For Life!",
            features: [
                { isActive: true, label: "1 Log in" },
                { isActive: true, label: "Track Inbound Inventory" },
                { isActive: false, label: "Manage Bundles" },
                { isActive: false, label: "Create To-Do Lists" },
                { isActive: false, label: "Sync with Amazon Account" },
            ],
            isActive: false,
        },
        {
            planId: "advanced_yearly",
            planTitle: "Advanced Account",
            price: "£189.90",
            discountPrice: "£149.90",
            timeline: "p/year",
            description:
                "First 500 - Price Locked In For Life!",
            features: [
                { isActive: true, label: "10 Loggins" },
                { isActive: true, label: "Track Inbound Inventory" },
                { isActive: false, label: "Manage Bundles" },
                { isActive: false, label: "Create To-Do Lists" },
                { isActive: false, label: "Sync with Amazon Account" },
            ],
            isActive: false,
        }
    ],
};

export { serverEndpoint, pricingList };